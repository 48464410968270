import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionImage from '../components/SectionImage'
import Layout from '../components/Layout'
import { useLangContext } from '../context/lang.context'

const content = {
  title: {
    pl: 'Ślepa uliczka',
    en: 'End of the road',
  },
  back: {
    pl: '404 Error',
    en: '404 Error',
  },
  desc: {
    pl: 'Wygląda na to, że strona, którą chcesz odwiedzić nie istnieje. Kilknij logo, aby wyświetlić widok główny!',
    en: 'Seems like the page you are looking for does not exist. Go to homepage by clicking the logo!',
  },
}

const NotFoundPage = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      image: file(absolutePath: { regex: "/images/404/intro2.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <SectionImage
        title={content.title[lang]}
        desc={content.desc[lang]}
        back={content.back[lang]}
        image={getImage(query.image)}
        imagePosition='center top'
      />
    </Layout>
  )
}

export default NotFoundPage
